const HTTP="https://"
// const CONTENT_MANAGER_IP_ADDRESS="1inamillion.in"
// const PROFILER_ENGINE_IP_ADDRESS="1inamillion.in"
// const NOTIFICATION_DOMAIN_IP_ADDRESS="1inamillion.in"
// const SPINTHEWHEEL_IP_ADDRESS="1inamillion.in";
// const SPS_IP_ADDRESS="1inamillion.in";
// const CONTEST_IP_ADRESS="1inamillion.in";
// const APPLOGGER_IP_ADDRESS="1inamillion.in";
// const API_CONNECTOR_IP_ADDRESS="1inamillion.in";

// export const GAMEPORTALURL='//viservices.in/vicombogameportaluat/home?source=vi'
// export const STARTALKURL='//viservices.in/vilstartalkUat/home'
// export const CONTESTURL='//viservices.in/vilcontestuat'

export const GAMEPORTALURL='//viservices.in/vicombogameportal/home?source=vi'
export const STARTALKURL='//viservices.in/vilstartalk/home'
export const CONTESTURL='//viservices.in/vilcontest'

const RECHARGE_PAYTM_IP_ADDRESS="viservices.in";
const CONTENT_MANAGER_IP_ADDRESS="viservices.in"
const PROFILER_ENGINE_IP_ADDRESS="viservices.in"
const NOTIFICATION_DOMAIN_IP_ADDRESS="viservices.in"
const SPINTHEWHEEL_IP_ADDRESS="viservices.in";
const SPS_IP_ADDRESS="viservices.in";
const CONTEST_IP_ADRESS="viservices.in";
const APPLOGGER_IP_ADDRESS="viservices.in";
const API_CONNECTOR_IP_ADDRESS="viservices.in";

export const LOGIN_URL="https://viservices.in/viservicesportal/login"
export const APP_HOME_URL="https://viservices.in/viservicesportal/home"
export const APPLICATION_MAIN_URL="http://viservices.in"

// export const LOGIN_URL="http://localhost:3000/viservicesportal/login"
// export const APP_HOME_URL="http://localhost:3000/viservicesportal/home"
// export const APPLICATION_MAIN_URL="http://viservices.in/viservicesportal/splash"


// export const LOGIN_URL="https://1inamillion.in/viservicesportal/login"
// export const APP_HOME_URL="https://1inamillion.in/viservicesportal/home"
// export const APPLICATION_MAIN_URL="https://1inamillion.in/viservicesportal/splash"


// const CONTENT_MANAGER_IP_ADDRESS="vi.mv1.in"
// const PROFILER_ENGINE_IP_ADDRESS="vi.mv1.in"
// const NOTIFICATION_DOMAIN_IP_ADDRESS="vi.mv1.in"
// const SPINTHEWHEEL_IP_ADDRESS="vi.mv1.in";
// const SPS_IP_ADDRESS="vi.mv1.in";
// const CONTEST_IP_ADRESS="vi.mv1.in";
// const APPLOGGER_IP_ADDRESS="vi.mv1.in";
// const API_CONNECTOR_IP_ADDRESS="v1.mv1.in";
// http://viservices.in/GnNotificationManager/

//=============PROD=======================
const PROFILER_PROJECT_NAME="Profiler"
const CONTENT_MANAGER_PROJECT_NAME="ContentManager2"
const CONTEST_PROJECT_NAME="ContestLeague"
const SPINTHEWHEEL_PROJECT_NAME="SpinTheWheel"
const SPS_PROJECT_NAME="SPSBilling"
const APPLOGGER_PROJECT_NAME="AppLogger"
const API_CONNECTOR_PROJCT_NAME="ApiConnector";
const RECHARGE_PAYTM_PROJECT_NAME="PaytmIntegration"


export const APP_ENVIRONMENT = "PROD"
// export const APP_ENVIRONMENT = "UAT"

export const SUBS_PACK_API_PARAM="/v1/subscribeWithPackName?packName=";
export const CHECK_SUBS_GENERIC_API_PARAM="/v1/checkSubscriptionGeneric";
export const CHECK_SUBS_API_PARAM="/v1/checkSubscriptionWithPackName?packName=";
export const CHECK_SUBS_TNB_PARAM="/v1/checkSubscriptionMciTnb?packName=";
export const SECOND_CONSENT="/v1/secondConsent";

export const ENC_KEY="ONMOHUB_E38D449E";

//=============UAT=======================
// const PROFILER_PROJECT_NAME="ProfilerUat"
// const CONTENT_MANAGER_PROJECT_NAME="ContentManager2Uat"
// const CONTEST_PROJECT_NAME="ContestLeagueUat"
// const SPINTHEWHEEL_PROJECT_NAME="SpinTheWheelViUAT"
// const SPS_PROJECT_NAME="SPSBillingUat"
// const APPLOGGER_PROJECT_NAME="AppLogger"
// const API_CONNECTOR_PROJCT_NAME="ApiConnector";
// const RECHARGE_PAYTM_PROJECT_NAME="PaytmIntegration"


export const SPS_COMBINED_IP=HTTP+SPS_IP_ADDRESS+"/"+SPS_PROJECT_NAME; 



export const APPLICATION_NAME="liveportal"
export const CONTENT_COMBINED_IP=HTTP+CONTENT_MANAGER_IP_ADDRESS+"/"+CONTENT_MANAGER_PROJECT_NAME;
export const RECHARGE_PAYTM_ENGINE_COMBINED_IP=HTTP+RECHARGE_PAYTM_IP_ADDRESS+"/"+RECHARGE_PAYTM_PROJECT_NAME; 

export const API_CONNECTOR_ENGINE_COMBINED_IP=HTTP+API_CONNECTOR_IP_ADDRESS+"/"+API_CONNECTOR_PROJCT_NAME; 

export const CONTEST_ENGINE_COMBINED_IP=HTTP+CONTEST_IP_ADRESS+"/"+CONTEST_PROJECT_NAME; 

export const Profiler_ENGINE_COMBINED_IP=HTTP+PROFILER_ENGINE_IP_ADDRESS+"/"+PROFILER_PROJECT_NAME;
export const SPS_ENGINE_COMBINED_IP=HTTP+SPS_IP_ADDRESS+"/"+SPS_PROJECT_NAME;

export const CONTEST_GET_CONTEST_META_INFO="/contestMetaInfo";
export const GET_QUESTIONS_ON_CONTEST_ENTRY = "/getQuestionsOnContestEntry";
export const GET_SUBSEQUENT_QUESTIONS = "/getSubsequentQuestions";

export const SIGNIN_API = "/v2/submitLoginOrSignUp";
export const VALIDATE_LOGIN_OTP_API="/v1/validateOtpAndLogin";
export const ACTIVE="ACTIVE";

export const APP_KEY_SUBS="vilgame";
export const NOT_SUBSCRIBED="Not subscribed";
export const SUBSCRIBED="subscribed";
export const CAMPAIGN_BANNER_API=HTTP+CONTENT_MANAGER_IP_ADDRESS+"/"+CONTENT_MANAGER_PROJECT_NAME+"/v1/getPromotionDetails";
export const APPLOGGER_COMBINED_IP=HTTP+APPLOGGER_IP_ADDRESS+"/"+APPLOGGER_PROJECT_NAME;
export const HOME=HTTP+CONTENT_MANAGER_IP_ADDRESS+"/"+CONTENT_MANAGER_PROJECT_NAME+"/v1/fetchHomePage";
export const MENU=HTTP+CONTENT_MANAGER_IP_ADDRESS+"/"+CONTENT_MANAGER_PROJECT_NAME+"/v1/fetchMenu";
export const LOYALTY=HTTP+CONTENT_MANAGER_IP_ADDRESS+"/"+CONTENT_MANAGER_PROJECT_NAME+"/v1/fetchSettings?settingKey=loyalty"
export const ABOUT=HTTP+CONTENT_MANAGER_IP_ADDRESS+"/"+CONTENT_MANAGER_PROJECT_NAME+"/v1/fetchSettings?settingKey=aboutUs"
export const SUPPORT=HTTP+CONTENT_MANAGER_IP_ADDRESS+"/"+CONTENT_MANAGER_PROJECT_NAME+"/v1/fetchSettings?settingKey=help"
export const FEEDBACK=Profiler_ENGINE_COMBINED_IP+"/v1/getFeedback"
export const UPDATE_FEEDBACK=Profiler_ENGINE_COMBINED_IP+"/v1/updateFeedback"
export const USER_REGISTRATION_API_URL=HTTP+NOTIFICATION_DOMAIN_IP_ADDRESS+"/GnNotificationManagerV2/v1/user/register"
export const GET_USER_NOTIFICATION_API_URL=HTTP+NOTIFICATION_DOMAIN_IP_ADDRESS+"/GnNotificationManagerV2/v1/gnNotification/inapp"
export const CHECK_NOTIFICATION_STATUS_API_URL=HTTP+NOTIFICATION_DOMAIN_IP_ADDRESS+"/GnNotificationManagerV2/v1/user/checkStatus"
export const DISABLE_NOTIFICATION_API_URL=HTTP+NOTIFICATION_DOMAIN_IP_ADDRESS+"/GnNotificationManagerV2/v1/user/unregister"
export const SPINTHEWHEEL_COMBINED_IP=HTTP+SPINTHEWHEEL_IP_ADDRESS+"/"+SPINTHEWHEEL_PROJECT_NAME;

export const FETCH_MCI_EVENT= Profiler_ENGINE_COMBINED_IP + '/v1/fetchMciEvents?msisdn=';
export const OPERATOR="vi";
export const IDEA_OPERATOR="vi";
export const VODAFONE_OPERATOR="vi";
export const CHANNEL='wap'
export const CIRCLE='ALL'
export const APP_ID=21;
export const DEVICE_ID='SYS';
export const APP_KEY="ViLive";
export const APP_BIGWIN_KEY="viBigwin";
export const TNB_PACK_NAME = 'VIAPPFREEMCITNB';
export const FAILURE_REDIRECT_URL_TNB = 'http://mv1.in/tniv/pr';

export const APP_KEY_VODAFONE="ViLiveVodafone";
export const APP_KEY_MCI_TNB_VI="ViLiveTNBMCI";
export const APP_KEY_MCI_TNB_VODA="ViLiveVodafoneTNBMCI";
export const IDEA_APP_KEY="vilgame";
export const VODAFONE_APP_KEY="vilgameVodafone";

export const APP_NAME="villive";
//export const ACCESS_TOKEN='eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5MTk3MTE2NjI1MDYiLCJleHAiOjE2MDU5NjQ0MTAsImlhdCI6MTYwMzM3MjQxMH0.LHoxh6ItkSh7bdgrWJ51BebpL9SUL_rl46prFFl-O2zPvzLtBbkCQueSFuxGjDfVwPnP8HZgf5OL18NDQHmZZA'


export const LOGIN_ACTION='login'
export const HOME_ACTION='home'
export const REDEMPTION_ACTION='redemption'
export const QUIZ_ACTION='quiz'
export const MYCOUPON_ACTION='mycoupon'
export const BYCOUPON_ACTION='buycoupon'
export const SURPRISE_ACTION='surprise'
export const WINNER_ACTION='winner'

export const UNSUB_URL="http://mv1.in/gamev/gm";

export const EXIST_USER="existing_user";
export const NEW_USER="new_user";
export const RESEND_OTP="resend_otp";
export const RESET_PASSWORD="reset_password";

export const OTP_SCREEN=1;
export const SET_PWD_SCREEN=2;
export const VALIDATE_PWD_SCREEN=3;
export const HOME_SCREEN=4;
export const SUB_CATEGORY='viloyalty';
// ----- Spin The Wheel START -----
export const VENDOR_KEY='villive';
export const CAMPAIGN_ID=8;
export const SPINTHEWHEEL_TIME=60;

export const SPINTHEWHEEL_SESSION_TIME=6;

export const SESSION_ID_TIMEOUT=3000   //In Minutes

// ----- Spin The Wheel END -----

export const VIL_LIVE_CATEGORY_NAME="VIL LIVE Main Home";
export const ENTERTAINMENT_SUB_CATEGORY_NAME="Entertainment";
export const ADS_SUB_CATEGORY_NAME="AstrologyBanner";
export const UTILITY_SUB_CATEGORY_NAME="Utility Services";
export const REDEMPTION_SUB_CATEGORY_NAME="Redemption";
export const SPECIAL_BANNER_CATEGORY_NAME="Special offer";
export const ABOUT_SUB_CATEGORY_NAME="About";
export const HELP_SUB_CATEGORY_NAME="24x7 Help";
export const SUB_CATEGORY_LIST_OBJECT="content3";

export const CLEVERTAP_ACCOUNTID="487-8K4-895Z";
export const CLEVERTAP_PASSCODE="AYQ-KWA-CXKL";

export const MCA_REDIRECT_URL="http://mv1.in/mci/vi";

export const MENU_PROFILE="Profile";
export const MENU_LOYALTY="Loyalty Program";
export const Menu_ABOUT="About Services";
export const MENU_SUPPORT="24x7 Services";
export const MENU_RATE_US="Rate Us";

//export const MSISDN=9711662506;
export const COUNTRY_CODE="91"; 
export const languageName = "en";
export const APPLICATION_JSON="application/json";

export const ONLY_NUM_REGEX = /^[0-9]*$/;
export const ONLY_ALPHA_REGEX = /[^A-Za-z ]/g;
export const ONLY_ALPHA_SPACE_REGEX=/^([a-zA-Z]+\s)*[a-zA-Z]+$/;
export const VALIDATE_MOBILE_NO_REGEX = /^[6-9]\d{9}$/;
export const VALIDATE_PASSWORD_REGEX= /^(?=.*\d)(?=.*[@$!%*?&#'()+,-./:;<=>[\]^_`{|}~"])[A-Za-z\d@$!%*?&#'()+,-./:;<=>[\]^_`{|}~"]{6,}/

export function onlyCharacters (test)
{
  //console.log("inside isValid() method");  
  var pattern = new RegExp(ONLY_ALPHA_SPACE_REGEX);
  return (pattern.test(test));   

}
export function onlyDigits(test)
{
  //console.log("inside isValid() method");  
  var pattern = new RegExp(ONLY_NUM_REGEX);
  return (pattern.test(test));   

}

// -------------------------------------------------------
//export const SESSION_ID_TIMEOUT = "30";


export function copyObject (obj){
    return JSON.parse(JSON.stringify(obj));
}



const SECRET_KEY="MYSECRETKEY"

export function setObjectInSS(key,Object){
    sessionStorage[key] = Object;
}


export function getObjectFromSS(key){
    return JSON.parse(sessionStorage[key]);
}
// export function convertObjectBeforeSaving(Object){
//     var encData = CryptoJS.AES.encrypt(JSON.stringify(Object), SECRET_KEY).toString();
//     return encData;
// }
// export function parseObjectBeforeGet(key){
  
//     var bytes = CryptoJS.AES.decrypt(sessionStorage[key], SECRET_KEY);
//     var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
//     return JSON.parse(decryptedData);
// }

export function isValidMsisdn (msisdn)
{
  //console.log("inside isValid() method");  
  var pattern = new RegExp(VALIDATE_MOBILE_NO_REGEX);
  return !(msisdn == null || msisdn == '' || !pattern.test(msisdn));   

}

export function isValidPassword (password)
{
  //console.log("inside isValid() method");  
  var pattern = new RegExp(VALIDATE_PASSWORD_REGEX);
  return (pattern.test(password));

}

//return !(msisdn == null || msisdn == '' || !pattern.test(msisdn));

export function isValidOtp (otp){
   // console.log("inside isValidOtp() method");  
    var pattern = new RegExp(ONLY_NUM_REGEX);
    return !(otp == null || otp == ''  );
}