// Import the functions you need from the SDKs you need

import firebase, { initializeApp } from "firebase/app";


// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var appName=null

export const initializeFirebaseAnalytics = () => {
    console.log('INSIDE INITIALIZE FIREBASE')

    const firebaseConfig = {
        apiKey: "AIzaSyAkMl3-5EtEHDzm2Cnl6YjFZsOajuzEYDU",
        authDomain: "villive.firebaseapp.com",
        databaseURL: "https://villive.firebaseio.com",
        projectId: "villive",
        storageBucket: "villive.appspot.com",
        messagingSenderId: "181110717950",
        appId: "1:181110717950:web:cb61e7fcd2e0d4187094af",
        measurementId: "G-G4H5261EZC"
    };

    const app = initializeApp(firebaseConfig, "secondary");
    appName = app;
    // firebase.analytics(app)
}

export const logEvents = (event) => {

    console.log('INSIDE LOG EVENTS ->   '+ event )


    firebase.analytics(appName).logEvent(event);
    console.log('DONE ANALYTICS' )

}

