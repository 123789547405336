// import React from 'react';
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
// import * as serviceWorker from './serviceWorker';
import * as Notification from './components/pushNotification'
import * as Analytics from './components/Analytics'
import Loading from './loader'
// import { Provider } from 'react-redux'
// import store from './store'
// const Loading = lazy(() => import('./loader'));
const App = lazy(() => import('./App'));



//import { BrowserRouter } from 'react-router-dom'

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('../firebase-messaging-sw.js')
//   .then(function(registration) {
//     console.log('Registration successful, scope is:', registration.scope);
//   }).catch(function(err) {
//     console.log('Service worker registration failed, error:', err);
//   });
// }

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', function() {
//     navigator.serviceWorker.register('service-worker.js').then(function(registration) {
//       // Registration was successful
//       console.log('ServiceWorker registration successful with scope: ', registration.scope);
//     }, function(err) {
//       // registration failed :(
//       console.log('ServiceWorker registration failed: ', err);
//     }).catch(function(err) {
//       console.log(err)
//     });
//   });
// } else {
//   console.log('service worker is not supported');
// }

ReactDOM.render(
  // <React.StrictMode>
    <Suspense fallback={<Loading />
    }>
      {/* <Provider store={store}> */}
        <App />
      {/* </Provider> */}
    </Suspense>
  // </React.StrictMode>
  ,
  document.getElementById('root')
  ,
  Notification.initializeFirebase(),
  Analytics.initializeFirebaseAnalytics(),
  
);

// If you want 
// unregister() to register() below. Note this comes with some pitfallsyour app to work offline and load faster, you can change.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
